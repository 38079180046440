.selectablecell {
   padding:1rem;
   background-color: var(--colorWhite);
   color:var(--colorBlack);
   margin-bottom:0.2rem;
   transition: all 100ms ease-out;
   width: 100%;
   cursor:pointer;
}

.selectablecell:hover {
   background-color: rgba(var(--colorWhite), 0.2);
}

.selectablecell.dark {
   background-color: var(--colorLight);
}

.selectablecell.active {
   border: 2px solid var(--colorPrimary);
}
