nav.menu {
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   height: 100vh;
   width: var(--widthMenu);
   background-color: var(--colorPrimary);
   background: linear-gradient(15deg, var(--colorSecondary) 0, var(--colorPrimary) 100%);
   display: flex;
   flex-direction: column;
   align-content: center;
   padding-top: 4rem
}

nav.menu a {
   color: var(--colorWhite);
   text-align: center;
   padding: 1rem 1.5rem;
   transition: all 300ms ease-out;
   position: relative;
}

nav.menu a .active {
   background-color: var(--colorSecondary);
   padding: 0.5rem 0;
   border-radius: 12px;
}

nav.menu a:hover {
   transform: rotate(-5deg);
}

nav.menu a.end {
   position: absolute;
   bottom: 2rem;
   left: 0;
   width: 100%;
   cursor: pointer;
}

nav.menu button {
   padding: 1rem 1.5rem;
   transition: all 300ms ease-out;
   position: relative;
   background-color: transparent;
   border: 0;
   outline: none;
   cursor: pointer;
   color: var(--colorWhite);
}

nav.menu button:hover svg{
   transform: rotate(-5deg);
}

