.datepicker {
   position: relative;
   width: 100%;

   font-size: 0.9rem;
   --numDaysPerRow: 5;
}

.datepicker .datepicker--input {
   z-index: 10;
   background-color: var(--colorNear);
   padding: 0.75rem 0.5rem;
   padding-top: 1rem;
   width: 100%;
   outline: none;
   border: 0;
   border-radius: 2px;
}

.datepicker .datepicker--input label {
   position: absolute;
   top: 3px;
   left: 0.5rem;
   font-size: 0.7rem;
   color: var(--colorPrimary);
}

.datepicker .datepicker--menu {
   z-index: 20;
   position: absolute;
   margin-top: 0.5rem;
   width: 100%;
   min-width: 120px;
   background-color: var(--colorNear);
   border: 1px solid var(--colorGrey);
}

.datepicker--calendar {
   display: flex;
   justify-content: flex-start;
   flex-wrap: wrap;
}

.datepicker--calendar-month {
   width: 100%;
}

.datepicker--calendar-month-head {
   display: flex;
   justify-content: space-between;
   width: 100%;
   padding: 1rem;
}

.datepicker--calendar-nav {
   cursor: pointer;
   user-select: none;
   font-size: 1.1rem;
}

.datepicker--calendar-day {
   width: calc(100% / var(--numDaysPerRow));
   line-height: 1.8;
   text-align: center;
   cursor: pointer;
   border-radius: 4px;
}

.datepicker--calendar-day:hover {
   background-color: var(--colorGrey);
   color: var(--colorWhite);
}

.datepicker--calendar-day.today {
   background-color: var(--colorPrimary);
   color: var(--colorWhite);
}

.datepicker--calendar-day.current {
   background-color: var(--colorDarkGrey);
   color: var(--colorWhite);
}
