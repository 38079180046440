.customer--quota {
   color: var(--colorDarkGrey);
   font-size: 0.9rem;
}

.customer--quota svg {
   margin-left:0.5rem;
}
.customer--quota a {
   font-weight: bold;
   cursor: pointer;
}

.customer--quota .value {
   color: var(--colorBlack);
}
.customer--quota .euro {
   word-break: keep-all;
   white-space: nowrap;
   color: var(--colorDarkGrey)
}

.customer--quota .link {
   color: var(--colorPrimary);
   cursor: pointer;
   text-decoration: none;
}

.customer--quota .link:hover {
   text-decoration: underline;
}
