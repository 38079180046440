.welcome {
   display: flex;
   justify-content: space-between;
   width: 100%;
}

.welcome .welcome--first {
   font-size: 0.9rem;
   align-items: center;
}

.welcome .welcome--first img {
   height: 40px;
   margin-right: 0.5rem;
}

.welcome .welcome--last {
   display: flex;
   justify-content: flex-end;
   flex-direction: column;
   text-align: right;
}

.welcome .welcome--name {
   font-size: 1.2rem;
   font-weight: normal;
}

.welcome .welcome--date {
   font-size: 0.9rem;
   color: var(--colorGrey);
}
