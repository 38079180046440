.filter--select-item {
   display: inline;
   color: var(--colorWhite);
   background-color: var(--colorGrey);
   padding: 2px 6px;
   font-size: 0.9rem;
   cursor: pointer;
   margin-right:2px;
   margin-bottom:2px;
}

.filter--select-item.block {
   display:block;
}

.filter--select-item.active {
   background-color: var(--colorPrimary);
   color: var(--colorWhite);
}
