:root {
   font-size: 15px;

   --colorPrimary: #287809;
   --colorSecondary: #2d8e2d;
   --colorError: #bf0d55;
   --colorWarning: #eea243;
   --colorBlack: #001b2e;
   --colorLight: #e5e5e5;
   --colorNear: #f5f5f5;
   --colorGrey: #b8b5b5;
   --colorDarkGrey: #5a5656;
   --colorWhite: #FFF;

   --widthList: 40%;
   --widthMenu: 100px;
}

* {
   box-sizing: border-box;
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
   sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
   monospace;
}

a {
   color: var(--colorPrimary);
   text-decoration: none;
}

a:hover {
   text-decoration: underline;
}

mark {
   background-color: var(--colorSecondary);
   color: var(--colorWhite);
}

.row {
   display: flex;
   width: 100%;
   justify-content: space-between;
}

.row.vcenter {
   align-items: center;
}

.row.start {
   justify-content: flex-start;
}

.row.wrap {
   flex-wrap: wrap;
}

.row.end {
   justify-content: flex-end;
}

.row.center {
   justify-content: center;
}

.width-10 {
   width: 10%;
}

.width-15 {
   width: 15%;
}

.width-20 {
   width: 20%;
}

.width-25 {
   width: 25%;
}

.width-30 {
   width: 30%;
}
.width-33 {
   width: 33.33%;
}

.width-40 {
   width: 40%;
}

.width-50 {
   width: 50%;
}

.width-60 {
   width: 60%;
}

.width-70 {
   width: 70%;
}

.width-80 {
   width: 80%;
}

.width-90 {
   width: 90%;
}

.width-100 {
   width: 100%;
}

.align-right {
   text-align: right;
}

.font-normal {
   font-size: 1rem;
}

.font-small {
   font-size: 0.9rem;
}

.font-smaller {
   font-size: 0.8rem;
}

.font-smallest {
   font-size: 0.7rem;
}

.font-big {
   font-size: 1.1rem;
}

.font-bigger {
   font-size: 1.2rem;
}

.font-biggest {
   font-size: 1.3rem;
}

.color-primary {
   color: var(--colorPrimary);
}

.color-secondary {
   color: var(--colorSecondary);
}

.color-error {
   color: var(--colorError);
}

.color-warning {
   color: var(--colorWarning);
}

.color-black {
   color: var(--colorBlack);
}

.color-light {
   color: var(--colorLight);
}

.color-near {
   color: var(--colorNear);
}

.color-grey {
   color: var(--colorGrey);
}

.color-darkgrey {
   color: var(--colorDarkGrey);
}

.color-white {
   color: var(--colorWhite);
}


.formular--error {
   color: var(--colorError);
   font-size: 0.8rem;
   padding-bottom:0.2rem;
}

/* overwrite notification styles */
.rnc__notification-message {
   padding: 1rem;
   font-size: 1rem;
}

#dashboard--wood {
   --color-1: var(--colorPrimary);
   --color-2: var(--colorWarning);
   --color-3: var(--colorSecondary);
   --color-4: rgba(230, 30, 30, 0.6);
   --color-5: rgba(230, 30, 30, 1.0);
   --color-6: rgba(230, 30, 30, 1.0);
   --color-7: rgba(230, 30, 30, 0.8);
   --color-8: rgba(230, 30, 30, 0.6);
   --color-9: rgba(230, 30, 30, 0.4);
   --color-10: rgba(230, 30, 30, 0.2);
}

#dashboard--wood .data {
   transform: translateY(-1.5rem);
   font-size: 0.9rem;
}


@media screen and (max-width: 800px) {
   /* for mobile this class should remove content */
   .no-mobile {
      display:none;
   }
}
