main.content {
   width: 100vw;
   min-height: 100vh;
}

main.content aside {
   position: fixed;
   top: 0;
   bottom: 0;
   height: 100vh;
   left: var(--widthMenu);
   padding: 2rem 1rem;
   background-color: var(--colorLight);
   width: calc(var(--widthList) - var(--widthMenu));

   /* hide scrollbar but allows scrolling */
   -ms-overflow-style: none; /* for Internet Explorer, Edge */
   scrollbar-width: none; /* for Firefox */
   overflow-y: scroll;
}

main.content aside::-webkit-scrollbar {
   display: none; /* for Chrome, Safari, and Opera */
}

main.content section {
   padding: 2rem;
   padding-left: calc(var(--widthList) + 2rem);
}

main.content section.noside {
   padding-left: calc(var(--widthMenu) + 2rem);
   width: 100%;
}
