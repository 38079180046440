.yesno--container {
   display: flex;
   font-size:0.8rem;
   align-items: center;
   color:var(--colorPrimary);
}

.yesno {
   --ynWidth: 100px;
   margin-right:1rem;

   background-color: var(--colorGrey);
   width: var(--ynWidth);
   display: flex;
   justify-content: center;
}

.yesno .cell {
   font-size: 0.7rem;
   padding: 0.2rem;
   text-align: center;
   width: calc(var(--ynWidth) / 2);
   color: var(--colorLight);
   cursor:pointer;
   transition: all 300ms ease-in-out;
}

.yesno .cell.yes {
   font-weight: bold;
   background-color: var(--colorSecondary);
   color: var(--colorWhite);
}

.yesno .cell.no {
   font-weight: bold;
   background-color: var(--colorError);
   color: var(--colorWhite);
}
