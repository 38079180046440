.dropdown {
   position: relative;
   width: 100%;
}

.dropdown .dropdown--input {
   z-index:10;
   background-color: var(--colorNear);
   padding: 0.75rem 0.5rem;
   padding-top: 1rem;
   width: 100%;
   outline: none;
   border: 0;
   border-radius: 2px;
}

.dropdown .dropdown--input label {
   position: absolute;
   top: 3px;
   left: 0.5rem;
   font-size: 0.7rem;
   color: var(--colorPrimary);
}


.dropdown .dropdown--input.error  {
   border: 1px solid var(--colorError);
   color: var(--colorError);
}

.dropdown .dropdown--input.error label {
   color: var(--colorError);
}

.dropdown .dropdown--menu {
   z-index:20;
   position: absolute;
   margin-top: 0.5rem;
   width: 100%;
   max-height: 20rem;
   overflow-x: hidden;
   overflow-y: auto;
   background-color: var(--colorNear);
   border: 1px solid var(--colorGrey);
}

.dropdown .dropdown--menu .dropdown--menu-item {
   font-size: 0.9rem;
   padding: 0.5rem;
   cursor: pointer;
}

.dropdown .dropdown--menu .dropdown--menu-item:hover {
   background-color: var(--colorPrimary);
   color: var(--colorWhite);
}
