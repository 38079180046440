:root {
   font-size: 13px;
}

.customer--info {
   width: 21cm;
   height:29cm;
   padding: 2cm;
   position:relative;

}

.customer--info-logo {
   display:flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom:1cm;
}
.customer--info-logo img {
   text-align: left;
   width:72px;
}

.customer--info-logo .text {
   font-weight:bold;
   color:var(--colorPrimary);
   font-size:0.9rem;
}

.customer--info-separator {
   border-bottom:1px solid var(--colorGrey);
}

.customer--info-logo h1 {
   font-size: 0.9rem;
   font-weight: normal;
   padding: 0;
   margin: 0;
}

.customer--info-content {
   font-size: 1rem;
}

.customer--info-content .key--value {
   display:flex;
   justify-content: flex-start;
}

.customer--info-content .key--value .key {
   width:2cm;
}

.customer--info-content .bold {
   font-weight: bold;
}

.customer--info-content .bigger {
   font-size: 1.28rem;
}

.customer--info-content .smaller {
   font-size: 0.9rem;
}
