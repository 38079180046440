.error--handler {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   width: 100vw;
   height: 100vh;
   background-color: var(--colorWhite);
   display:flex;
   justify-content: center;
   align-items: center;
}

.error--handler-bg {
   position: fixed;
   left: 0;
   top: 0;
   width: 100vw;
   height: 100vh;
   background: linear-gradient(82.29deg, rgba(255,255,255,1) 52.4%, rgba(255, 255, 255, 0.3) 99.83%), url(../../assets/forrest.jpg) no-repeat center center;
   background-size: cover;
}

.error--handler-container {
   width:50vw;
   padding:2rem;
   height:100vh;
   display:flex;
   flex-direction: column;
   justify-content: center;
}

.error--handler-container .error--handler-title {
   font-size:1.3rem;
   font-weight: bold;
}

.error--handler-container .error--handler-message {
   font-size:1rem;
}

.error--handler-container img {
   width:128px;
   height:auto;
}
