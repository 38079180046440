.button {
    border: 0;
    outline: none;
    text-align: center;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    border-radius: 4px;
    cursor: pointer;
}

.button.primary {
    background-color: var(--colorPrimary);
    color: var(--colorWhite);
}

.button.outline {
    background-color: transparent;
    border: 1px solid var(--colorPrimary);
    color: var(--colorPrimary);
}

.button.outline.disabled {
    color: var(--colorDarkGrey);
    pointer-events: none;
    cursor: not-allowed;
}

.button.disabled {
    background-color: var(--colorGrey);
    color: var(--colorDarkGrey);
    pointer-events: none;
    cursor: not-allowed;
}

.button.delete {
    background-color: var(--colorError);
    color: var(--colorWhite);
}

.button.cancel {
    background-color: transparent;
    color: var(--colorPrimary);
}
