.signin--container {
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   width: 100vw;
   height: 100vh;
   background: rgb(255, 255, 255);
   background: url(../../assets/forrest.jpg) no-repeat;

   background-size: cover;
   background-position: center center;
   position: fixed;

   animation: mover 15s ease-in-out infinite alternate-reverse;
}

.signin--container::before {
   content: " ";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: linear-gradient(148deg, rgba(255, 255, 255, 0.5) 10%, rgba(0, 0, 0, 0.7) 100%);
   width: 100vw;
   height: 100vh;
}

.signin--container .signin--box {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   padding: 2rem;
   background: rgba(255, 255, 255, 0.3);
   box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
   backdrop-filter: blur(6px);
   -webkit-backdrop-filter: blur(6px);
   border-radius: 10px;
   border: 1px solid rgba(255, 255, 255, 0.18);
   width: 400px;
}

.signin--container .signin--box button {
   width: 100%;
}

.signin--container .signin--box.error {
   animation: shake 1s ease-in-out;
}

.signin--container .signin--box h1 {
   font-weight: bold;
   font-size: 1.6rem;
   padding: 0;
   margin: 0;
}

.signin--container .signin--box h2 {
   font-weight: 300;
   font-size: 1.2rem;
   padding: 0;
   margin: 0;
}

.signin--container .signin--card {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   line-height: 1;
   color: #fff;
}

.signin--container .signin--card h1 {
   font-size: 6rem;
   color: #fff;
   opacity: 0.6;
   font-weight: bold;
   letter-spacing: -0.4rem;
   line-height: 0.8;
   margin-bottom: 1rem;
}

.signin--container .signin--card h3 {
   text-transform: uppercase;
}

.signin--container .signin--card-content {
   padding: 1rem;
   background: rgba(255, 255, 255, 0.6);
   border-radius: 4px;
   color: #000;
   width: 80%;
   box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.signin--container .signin--card-content strong {
   width: 4ch;
   display:inline-block;
}

@keyframes shake {
   0%, 100% {
      transform: translateX(-50%) translateY(-50%);
   }
   20%, 40%, 60%, 80% {
      transform: translateX(calc(-50% - 6px)) translateY(-50%) rotateZ(-4deg);
   }
   10%, 30%, 50%, 70% {
      transform: translateX(calc(-50% + 6px)) translateY(-50%) rotateZ(4deg);
   }
}


@keyframes mover {
   from {
      background-position-x: 20%;
   }
   to {
      background-position-x: 50%;
   }

}
