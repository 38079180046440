.tile {
    background-color: var(--colorWhite);
    color: var(--colorDarkGrey);
    padding: 2rem;
    text-align: left;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
    transition: all 300ms ease-out;
    height:100%;
}
.tile:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
}

.tile.small {
    padding: 1rem;
}

.tile .key {
    font-size: 1rem;
}

.tile .value {
    font-size: 3rem;
    font-weight: bolder;
}
