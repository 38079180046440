.quota--item-name {
   font-weight: bold;
}

.quota--item-quota {
   font-size: 0.7rem;
   display: block;
}

.quota--export-filter {
   background-color: var(--colorDarkGrey);
   color: var(--colorWhite);
   padding: 0.5rem;
   font-size: 0.8rem;
}


.quota--edit-link {
   color: var(--colorPrimary);
   padding:0.2rem 0;
   font-weight: bold;
   display: block;
}
