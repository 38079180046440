.input {
    position: relative;
}

.input label {
    position: absolute;
    top: 3px;
    left: 0.5rem;
    font-size: 0.7rem;
    color: var(--colorPrimary);
}

.input.error input {
    border: 1px solid var(--colorError);
    color: var(--colorError);
}

.input.error label {
    color: var(--colorError);
}


.input input {
    background-color: var(--colorNear);
    padding: 0.75rem 0.5rem;
    padding-top: 1rem;
    width: 100%;
    outline: none;
    border: 0;
    border-radius: 2px;
}

