.history {
   display: flex;
   justify-content: flex-start;
   width: 100%;
}

.history .history--date {
   width: 20%;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.history .history--date small {
   font-weight: bold;
   color: var(--colorPrimary);
   font-size: 0.8rem;
}

.history .history--name {
   width: 20%;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.history .history--text {
   width: 60%;
}
