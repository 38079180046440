.help {
   position: fixed;
   right: 0;
   top: 0;
   bottom: 0;
   height: 100vh;
   overflow-y: auto;
   width: 400px;
   background-color: #fff;
   box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
   padding: 1rem;
   font-size: 0.9rem;
   line-height: 1.1;
}

.help h1 {
   font-size: 1rem;
   font-weight: bold;
   text-transform: uppercase;
   color:  var(--colorPrimary);
}

.help p {
   color: var(--colorDarkGrey);
}
