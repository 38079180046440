.tag {
   font-size:0.8rem;
   padding: 2px 4px;
   border-radius:8px;
   text-align: center;
   display:inline-block;
   min-width:8ch;
}

.tag.primary {
   background-color: var(--colorPrimary);
   color: var(--colorWhite);
}
.tag.black {
   background-color: var(--colorBlack);
   color: var(--colorWhite);
}
.tag.grey {
   background-color: var(--colorGrey);
   color: var(--colorBlack);
}
.tag.outline {
   background-color: transparent;
   color: var(--colorBlack);
   border:1px solid var(--colorBlack);
}
