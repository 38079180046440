.contact--overlay {
   position:fixed;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   width:100vw;
   height:100vh;
   background-color:rgba(0,0,0,0.7);
   -webkit-backdrop-filter: grayscale(0.3);
   backdrop-filter: grayscale(0.3);
}

.contact {
   position: fixed;
   bottom: 0;
   right: 0;
   width: 38px;
   height: 38px;
   background-color: var(--colorWarning);
   color: var(--colorWhite);
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   transition: all 300ms ease-out;
}

.contact.open {
   background-color: var(--colorLight);
   color: var(--colorBlack);
   padding:1.5rem 2rem;
   width: 50vw;
   height: auto;
   justify-content: initial;
   align-items: initial;
   flex-direction: column;
}

.contact.open h1 {
   font-size:1.5rem;
   padding:0;
   margin:0;
}
