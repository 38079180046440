.select {
   border-radius: 8px;
   border:1px solid var(--colorDarkGrey);
   color: var(--colorDarkGrey);
   padding:0.5rem;
   font-size:0.9rem;
   margin-right:0.2rem;
   margin-bottom:0.2rem;
   cursor:pointer;
   user-select: none;
}

.select.active {
   border:1px solid var(--colorPrimary);
   color: var(--colorWhite);
   background-color: var(--colorPrimary);
}
