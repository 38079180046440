.card {
   position: relative;
   display: flex;
   flex-direction: column;
}

.card .card--title {
   font-weight: bold;
   font-size: 0.9rem;
   text-transform: uppercase;
}

.card .card--content {
   padding: 1rem;
   border-radius: 2px;
   background-color: var(--colorLight);
   color: var(--colorBlack);
   margin-bottom: 0.2rem;
   width: 100%;
   word-break: break-word;
}

.card .card--edit {
   position: absolute;
   color: var(--colorPrimary);
   cursor: pointer;
   right: 1rem;
   top: 2rem;
   font-size:0.8rem;
}

.card .card--edit:hover {
   text-decoration: underline;
}

.card .card--content p {
   margin: 0;
}
