.dot {
   background-color: var(--colorGrey);
   color: var(--colorBlack);
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 0.7rem;
   border-radius: 50%;
   width: 24px;
   height: 24px;
}

.dot.active {
   background-color: var(--colorPrimary);
   color: var(--colorWhite);
}

.dot.outline {
   background-color: transparent;
   border: 1px solid var(--colorGrey);
   color: var(--colorGrey);
}

.dot.outline.active {
   background-color: transparent;
   border: 1px solid var(--colorPrimary);
   color: var(--colorPrimary);
}
