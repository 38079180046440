.multilineinput {
   position: relative;
}

.multilineinput label {
   position: absolute;
   top: 3px;
   left: 0.5rem;
   font-size: 0.7rem;
   color: var(--colorPrimary);
}

.multilineinput.filled input {
   padding: 0.75rem 0.5rem ;
}

.multilineinput.filled label {
   display:none;
}

.multilineinput textarea {
   background-color: var(--colorNear);
   padding: 0.5rem;
   padding-top: 1rem;
   width: 100%;
   outline: none;
   border: 0;
   border-radius: 4px;
   resize: vertical;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
   sans-serif;
}

