.empty--content {
   text-align: center;
   padding-top: 25vh;
}

.empty--content .title {
   font-size: 1rem;
   color: var(--colorBlack);
}


